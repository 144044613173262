@import "../mainVariables";
@import "../mixins";

.partners-wrapper {
  width: 1140px;
  padding: 30px 0 60px ;
  border: 1px solid #EAECF0;
  background-color: #FCFDFE;

  .partners-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    color: #667085;
    margin-bottom: 45px;
    text-align: center;
  }

  .partners-list-wrapper {
    position: relative;

    .slick-slider {
      .slick-list {
        .slick-track {
          margin-left: auto;
          padding-top: 5px;
          @media screen and (max-width: 400px){
            margin-right: 90px;
          }
        }
      }
    }
    .slick-slide .slick-active{
      width: 300px;
    }

    .partner-item {
      //width: 90%;
      //height: 100%;
      //position: relative;
      width: 100%;
      height: 100%;
      margin-right: 20px;

      .partner-content {
        width: 100%;
        height: 100%;
        padding-top: 39%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          //height: 100%;
          //margin: auto;
          //transition: all 0.3s;
          padding: 0 10px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    width: 80%;
    padding: 30 $mainPadding 60px;
  }
  @media all and (max-width: 1050px) {
    padding-right: 0;
    top: unset!important;
    width: 100%!important;
    border: unset!important;
    .partners-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #475467;
      margin: 32px 0 8px 0;
    }
    .next-btn, .prev-btn {
      display: none;
    }
  }
}