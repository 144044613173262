@import "../mainVariables";
@import "../mixins";

.contacts-wrapper {
  width: 1140px;
  position: relative;
  background-color:white;
  padding: 100px 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .form-content{
    width: 100%;
    height: 260px;
    background-color: #3F64CA;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 64px 36px;
    .contacts-info-block{
      width: 48%;
      display: flex;
      flex-direction: column;
      .title{
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
        color: white;
        margin-bottom: 10px;
      }
      .description{
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: white;
      }
    }
    .input-wrapper{
      width: 48%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .sent-email{
        padding: 12px 54px;
        height: 54px;
        background-color:#3AA9C8;
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-left: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .custom-input-group{
        width: 70%;
        margin-bottom: unset;
        input{
          padding: 12px 14px;
          background-color: transparent;
          border: 1px solid white;
          color: white;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          &::placeholder{
            color: white;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    width: 100%;
  }
  @media screen and (max-width: 1050px){
    width: 100%;
    padding: 76px 0 40px;
    .form-content{
      width: 100%;
      flex-direction: column;
      padding: 32px 24px;
      height: unset;
      top: -40px;
      .contacts-info-block{
        width: 100%;
      }
      .input-wrapper{
        width: 100%;
        flex-direction: column;
        padding-top: 32px;
        .custom-input-group{
          width: 100%;
        }
        .sent-email{
          width: 100%;
          margin-top: 16px;
          height: 48px;
          margin-left: unset;
        }
      }
    }
  }
}
