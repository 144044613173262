@import "../../mainVariables";

.service-main-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 120px 0;
  .service-main-block-img {
    width: 46%;
    .service-main-block-img-relative {
      position: relative;
      padding-top: 70%;
      z-index: 1000;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .service-main-block-info {
    width: 49%;
    .service-main-block-info-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: $brandColor;
      margin-bottom: 24px;
    }
    .service-main-block-info-title {
      max-width: 712px;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: #101828;
      margin-bottom: 24px;
    }
    .service-main-block-info-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #475467;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .service-main-block {
    flex-direction: column;
    .service-main-block-img {
      width: 100%;
      margin: 0;
    }
    .service-main-block-info {
      width: 100%;
      margin: 50px 0 0 0;
      .service-main-block-info-title {
        font-weight: 500;
        font-size: 28px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .service-main-block {
    margin-bottom: 80px;
    align-items: center;
    .service-main-block-info {
      .service-main-block-info-title {
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #101828;
      }
      .service-main-block-info-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #475467;
        p{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left!important;
          color: #475467;
          span{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: left!important;
            color: #475467;
          }
        }
      }
    }
  }
}
