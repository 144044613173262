@import "../mainVariables";
@import "../mixins";

.contact-wrapper{
  //-webkit-user-select:none!important;
  //user-select: none!important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .top-block{
    width: 100%;
    padding: 60px 0 60px $mainPadding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info-block{
      width: 520px;
      .info-title{
        font-size: 48px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #101828;
      }
      .blocks-title{
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #3F64CA;
        display: flex;
        align-items: center;
        svg{
          margin-right: 18px;
        }
      }
      .information{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        margin: 8px 0 0 42px;
      }
      .email-block{
        margin-top: 52px;
        width: 100%;
        border: 1px solid #DEEAFC;
        padding: 20px 22px;
        display: flex;
        flex-direction: column;
      }
      .number-block{
        margin-top: 12px;
        width: 100%;
        border: 1px solid #DEEAFC;
        padding: 20px 22px;
        display: flex;
        flex-direction: column;
      }
      .address-block{
        margin-top: 12px;
        width: 100%;
        border: 1px solid #DEEAFC;
        padding: 20px 22px;
        display: flex;
        flex-direction: column;
      }
    }
    .contact-map-block{
      width: 54%;
      height: 720px;
      .yandexMap{
        width: 100%;
        height: 100%;
        .ymaps-2-1-79-map{
          width: 100%!important;
          height: 100%!important;

        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .top-block{
      padding: 40px $mainPadding;
      .info-block{
        width: 100%;
        .info-title{
          font-size: 36px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: -0.01em;
          text-align: center;
        }
      }
      .contact-map-block{
        display: none;
      }
    }
  }
}
