@import "../mainVariables";

.about_page-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .about-header {
    margin-bottom: 44px;
    margin-top: 98px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1140px;
    .about-header-name {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      color: $brandColor;
      margin-bottom: 16px;
    }
    .about-header-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #101828;
      margin-bottom: 20px;
    }
    .about-header-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #475467;
    }
  }

  .about-main {
    padding: 108px 0 124px 0;
    margin-bottom: 76px;
    background-image: url("../../images/about-line-pattern.png");
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 60% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1140px;
    .about-main-info {
      width: 49%;
      p{
        p{
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #475467;
        }
      }
      .about-main-info-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #475467;
      }
    }
    .about-main-img {
      width: 48%;
      .about-main-img-resonsive {
        position: relative;
        padding-top: 82%;
        z-index: 1000;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .about-information {
    width: 1140px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 140px;
    .about-information-image-block {
      width: 352px;
      height: 178px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-block: 6px;
      img {
        width: 48px;
        height: 48px;
      }
      .about-information-image-block-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #101828;
      }
      .about-information-image-block-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #475467;
      }
    }
  }

  @media screen and (min-width: 1550px) {
    .about-header {
      .about-header-title {
        width: 70%;
      }
      .about-header-description {
        width: 80%;
      }
    }
  }
  @media screen and (max-width: 1250px) {
    padding: 0 $mainPadding;
    .about-header {
      width: 100%;
      .about-header-title {
        width: 70%;
      }
      .about-header-description {
        width: 80%;
      }
    }
    .about-main{
      width: 100%;
    }
    .about-information{
      width: 100%;
    }
  }

  @media screen and (max-width: 1050px) {
    .about-header {
      margin-bottom: 32px;
      margin-top: 70px;
      .about-header-title {
        font-size: 40px;
        line-height: 52px;
      }
    }

    .about-main {
      flex-direction: column-reverse;
      align-items: center;
      background-image: none;
      padding: 0 $mainPadding;
      .about-main-info {
        margin-top: 30px;
        padding-inline: 50px;
        width: 100%;
        .about-main-info-text {
          text-align: left;
          font-size: 18px;
          line-height: 26px;
        }
      }
      .about-main-img {
        width: 100%;
        background-image: url("../../images/about-line-pattern.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 50px;
      }
    }

    .about-information {
      margin-bottom: 100px;
      .about-information-image-block {
        width: 250px;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .about-header {
      margin-top: 40px;
      margin-bottom: 24px;
      .about-header-title {
        width: 100%;
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: -0.01em;
        text-align: center;
        color: #101828;
      }
      .about-header-description {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #475467;
      }
    }

    .about-main {
      margin-bottom: 64px;
      .about-main-info {
        padding-inline: 16px;
        margin-top: 8px;
        .about-main-info-text {
          text-align: left;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .about-main-img {
        padding-inline: 16px;
      }
    }

    .about-information {
      margin-bottom: 0px;
      flex-direction: column;
      align-items: center;
      .about-information-image-block {
        margin-block: 16px;
        width: 100%;
      }
    }
  }
}
