@import "../fonts";
@import "../mainVariables";

.footer-wrapper {
  background-color: white;
  color: #667085;
  display: flex;
  align-items: center;
  justify-content: center;

  .desktop-footer-wrapper{
    width: 1140px;

    .top-part {
      display: flex;
      padding: 60px 0;
      .col{
        width: 40%;
        position: relative;
      }
      .right-block{
        width: 60%;
        display: flex;
        align-items: baseline;
        .col{
          width: 36%;
        }
      }
    }

    .bottom-part {
      border-top: 1px solid #EAECF0;
      padding: 32px 0;
      display: flex;
      justify-content: space-between;

      .copyright {
        color: #475467;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        display: flex;
        align-items: center;
      }

      .developed-by {
        display: flex;
        align-items: center;
        flex-direction: column;
        .develop{
          color: #D0D5DD;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: right;
          display: flex;
          align-items: center;
          .footer-logo {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            margin-left: 10px;
            height: 40px;
          }
        }
        .design{
          color: #D0D5DD;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: right;
          display: flex;
          align-items: center;
          p{
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: right;
            color: #D0D5DD;
            text-decoration: underline;
            margin-left: 6px;
          }
        }
      }
    }

    .col {
      color: #667085;

      .logo-wrapper {
        margin-bottom: 35px;
        @media screen and (max-width: 1050px){
          margin-bottom: 54px;
        }
      }


      .presentation-button{
        cursor: pointer;
        text-decoration: underline;
        position: absolute;
        bottom: 20px;

      }
      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #98A2B3;
        margin-bottom: 10px;
      }

      a {
        display: flex;
        align-items: center;
        color: #475467;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;


        &:hover, p:hover {
          color:black;
        }
      }


      p, a {
        color: #475467;
        font-size: 16px;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .text-wrapper{
      width: 350px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
    }

    .icons-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 32px;
      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-right: 16px;
        .social-icon {
          width: 34px;
          height: 34px;
          margin-right: 10px;
        }
        &:hover{
          color: black;
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width:1100px){
    .desktop-footer-wrapper{
      width: 100%;
      .top-part{
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 $mainPadding 60px;
        .col{
          width: 100%;
        }
        .right-block{
          width: 100%;
          flex-direction: column;
          .col{
            width: 96%;
          }
        }
        .col{
          margin-top: 20px;
          .text-wrapper{
            width: 100%;
          }
        }
      }
      .bottom-part{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0 $mainPadding;
        .copyright{
          text-align: left;
          color: #D0D5DD;
        }
      }
    }
  }

}