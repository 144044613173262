@import "../../mainVariables";
@import "../../mixins";
@import "../../fonts";

.page-start {
  //-webkit-user-select:none!important;
  //user-select: none!important;
  width: 100%;

  .image-proportion {
    padding-bottom: 41%;
    position: relative;
    width: 100%;

    img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      object-fit: cover;
      height: 100%;
    }

    .bg-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #06191D;
      opacity: 0.65;
    }

    .content{
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: white;
      @include flex-center;
      flex-direction: column;
      .start-title{
        width: 80%;
        font-family: Bold;
        font-size: 52px;
        overflow: hidden;
        margin-bottom: 30px;
        max-height: 154px;
        color: white;
      }
      .start-description{
        width: 60%;
        font-size: 18px;
        line-height: 30px;
        overflow: hidden;
        height: 86px;
        margin-bottom: 40px;
        color: white;
      }
      .link-button{
        margin: auto;
      }
    }
  }

  &.small-padding {
    .image-proportion {
      padding-bottom: 29%;
    }
  }

  @media screen and (max-width: 1600px){
    .image-proportion {
      .content{
        .start-title{
          font-size: 46px;
          margin-bottom: 30px;
          max-height: 136px;
        }
        .start-description{
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1400px){
    .image-proportion {
      .content{
        width: 60%;
        .start-title{
          font-size: 38px;
          margin-bottom: 30px;
          max-height: 112px;
        }
        .start-description{
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .image-proportion {
      .content{
        width: 80%;
        .start-title{
          font-size: 34px;
          margin-bottom: 30px;
          max-height: 100px;
        }
        .start-description{
          font-size: 16px;
          line-height: 26px;
          height: 76px;
        }
      }
    }
  }

  @media screen and (max-width: 700px){
    .image-proportion {
      padding-bottom: 133%;
      .content{
        width: 100%;
        padding: 0 $mainPadding;
        top: 70%;
        .start-title{
          width: 100%;
          font-size: 22px;
          margin-bottom: 18px;
          max-height: 54px;
        }
        .start-description{
          width: 90%;
          font-size: 12px;
          line-height: 20px;
          height: 58px;
          margin-bottom: 28px;
        }
      }
    }
    &.small-padding{
      .image-proportion {
        padding-bottom: 133%;
      }
    }
  }

  @media screen and (max-width: 500px){
    .image-proportion {
      .content{
        .start-title{
          font-size: 18px;
          margin-bottom: 12px;
          max-height: 54px;
        }
        .start-description{
          width: 90%;
          font-size: 12px;
          line-height: 20px;
          height: 78px;
          margin-bottom: 20px;
        }
      }
    }
  }
}