@import "../../mainVariables";


.service-types {
  padding: 140px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
    .service-types-info {
      width: 50%;
      .service-types-info-title {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
        color: #101828;
        margin-bottom: 24px;
      }
      .service-types-info-description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #475467;
      }
    }
    .service-types-image {
      width: 46%;
      .service-types-header {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        color: $brandColor;
        position: relative;
        right: 24%;
        top: -60px;
      }
      .service-types-image-relative {
        position: relative;
        padding-top: 78.3%;
        z-index: 1000;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 1050px) {
  .service-types {
    flex-direction: column-reverse;
    padding-block: 60px;
    &:nth-child(odd) {
      flex-direction: column-reverse;
    }
    .service-types-info {
      width: 100%;
      margin-top: 24px;
      .service-types-info-title {
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #101828;
      }
      .service-types-info-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #475467;
        p{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left!important;
          color: #475467;
          span{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: left!important;
            color: #475467;
          }
        }
      }
    }
    .service-types-image {
      width: 100%;
      .service-types-header{
        right: 0;
        left: 50px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .service-types {
    padding-block: 28px;
    .service-types-info-title {
      font-size: 26px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #101828;
    }
    .service-types-info-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: left;
      color: #475467;
      p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left!important;
        color: #475467;
        span{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left!important;
          color: #475467;
        }
      }
    }
  }
}
